import { Text, rem } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { ReactComponent as CheckCircleIcon } from "assets/images/icons/check_circle.svg";
import { ReactComponent as XCircleIcon } from "assets/images/icons/x_circle.svg";

/**
 * カスタマイズした Mantine の notifications
 *
 * 参考: https://mantine.dev/others/notifications/
 */
// eslint-disable-next-line import/no-named-export
export const customNotifications = {
  show: ({
    message,
    status = "info"
  }: {
    message: string | string[]; // 表示するメッセージ
    status?: "success" | "error" | "info"; // 表示ステータス
  }) =>
    notifications.show({
      icon:
        status === "success" ? (
          <CheckCircleIcon width={18} height={18} />
        ) : status === "error" ? (
          <XCircleIcon width={18} height={18} />
        ) : undefined,
      message:
        typeof message === "string"
          ? message // 文字列はそのまま表示
          : message.map((msg, index) => <Text key={index}>{msg}</Text>), // 文字列の配列は改行するため Text コンポーネントで囲む
      styles: (theme) => ({
        body: { flex: "initial", marginRight: 0 },
        description: {
          color: theme.white,
          fontSize: rem(13),
          fontWeight: "bold"
        },
        icon: {
          backgroundColor: "transparent",
          height: rem(18),
          marginRight: rem(4),
          width: rem(18)
        },
        root: {
          ":before": { display: "none" },
          backgroundColor:
            status === "success"
              ? "#6AB864E5"
              : status === "error"
              ? "#DA6464E5"
              : "#525252E5",
          justifyContent: "center",
          paddingLeft: rem(10)
        }
      }),
      withCloseButton: false
    })
};
