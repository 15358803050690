// eslint-disable-next-line import/no-named-as-default
import liff from "@line/liff";
import {
  Box,
  Button,
  Center,
  Loader,
  Modal,
  Overlay,
  Stack,
  Text
} from "@mantine/core";
import {
  useGetSmartReceiptTokenIssueUser,
  usePostSmartReceiptTokenIssue
} from "api/smart_receipt";
import MainLayout from "components/layouts/MainLayout";
import Loading from "components/modules/Loading";
import { PAGE_TITLE, SMART_RECEIPT_TERM_ENDPOINT } from "libs/constants";
import { useState, type FC } from "react";
import { useNavigate } from "react-router-dom";

const ConfirmationItem: FC<{ label: string; value: string }> = ({
  label,
  value
}) => (
  <Box mb="xs">
    <Text color="#707070" mb="xs">
      {label}
    </Text>
    <Box mb="xs" ml="md" mt="xs">
      <Text size="lg">{value}</Text>
    </Box>
  </Box>
);

interface ModalContent {
  buttons: React.ReactNode;
  text: string;
}

const SignupSmartReceiptWithModal: FC = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState<ModalContent>({
    buttons: null,
    text: ""
  });

  const {
    data: tokenIssueUserData,
    error: tokenIssueUserError,
    isValidating: isValidatingTokenIssueUser
  } = useGetSmartReceiptTokenIssueUser({
    refreshInterval: 0,
    revalidateOnFocus: false,
    revalidateOnReconnect: false
  });

  const {
    isMutating: isIssuingSmartReceiptToken,
    trigger: triggerSmartReceiptTokenIssue
  } = usePostSmartReceiptTokenIssue() || {};

  const isLoading = isValidatingTokenIssueUser || isIssuingSmartReceiptToken;
  const hasError = tokenIssueUserError;
  const hasRequiredData = tokenIssueUserData && !isValidatingTokenIssueUser;

  const handleSmartReceiptConnection = async () => {
    try {
      const result = await triggerSmartReceiptTokenIssue();
      if (result && result.sign_up_url) {
        setModalContent({
          buttons: (
            <>
              <Button
                fullWidth
                fz={16}
                onClick={() => {
                  liff.openWindow({ external: true, url: result.sign_up_url });
                  setIsModalOpen(false);
                  navigate("/");
                }}
                size="lg"
              >
                スマートレシートに遷移する
              </Button>
              <Button
                fullWidth
                fz={16}
                onClick={() => setIsModalOpen(false)}
                size="lg"
                variant="outline"
              >
                キャンセル
              </Button>
            </>
          ),
          text: "スマートレシートに遷移しますよろしいですか？"
        });
        setIsModalOpen(true); // モーダルを開くのはURLを取得した後
      } else {
        throw new Error("Failed to get sign_up_url");
      }
    } catch (error) {
      setModalContent({
        buttons: (
          <Button
            fullWidth
            fz={16}
            onClick={() => setIsModalOpen(false)}
            size="lg"
          >
            OK
          </Button>
        ),
        text: "スマートレシートに遷移できませんでした。会員登録情報を確認するか、再度試してください。"
      });
      setIsModalOpen(true); // エラー時もモーダルを開く
    }
  };

  if (isValidatingTokenIssueUser) {
    return <Loading pageTitle={PAGE_TITLE.SIGNUP_SMART_RECEIPT} />;
  }

  if (hasError || !hasRequiredData) {
    return (
      <MainLayout
        footer={false}
        header
        pageTitle={PAGE_TITLE.SIGNUP_SMART_RECEIPT}
      >
        <Stack spacing="md">
          <Text fw={800} size="lg">
            必要な情報が見つかりませんでした。前のページから再度アクセスしてください。
          </Text>
          <Button
            disabled={isIssuingSmartReceiptToken}
            fz={16}
            onClick={() => navigate(-1)}
            size="lg"
            variant="outline"
          >
            戻る
          </Button>
        </Stack>
      </MainLayout>
    );
  }

  return (
    <MainLayout
      footer={false}
      header
      pageTitle={PAGE_TITLE.SIGNUP_SMART_RECEIPT}
    >
      <Stack spacing="md">
        <Stack spacing="md">
          <Text fw={800} size="lg">
            連携内容を確認してください
          </Text>
          <Text>
            スマートレシートに会員登録を行うため、以下の会員情報をスマートレシートに連携します。
            ※会員情報が異なる場合は、マイページ→会員情報変更メニューより変更してください。
          </Text>
          <Stack spacing="xs">
            <ConfirmationItem
              label="会員番号"
              value={tokenIssueUserData?.card_number || ""}
            />
            <ConfirmationItem
              label="メールアドレス"
              value={tokenIssueUserData?.smart_receipt_account_id || ""}
            />
            <ConfirmationItem
              label="性別"
              value={tokenIssueUserData?.sex || ""}
            />
            <ConfirmationItem
              label="生年月"
              value={tokenIssueUserData?.birthyear_month || ""}
            />
            <ConfirmationItem
              label="郵便番号"
              value={tokenIssueUserData?.zip_code || ""}
            />
          </Stack>
        </Stack>
        <Box bg="white" bottom={16} mt="auto" pos="sticky">
          <Stack spacing="md">
            <Text align="center" size="sm">
              <Text
                component="span"
                onClick={() =>
                  liff.openWindow({
                    external: true,
                    url: SMART_RECEIPT_TERM_ENDPOINT
                  })
                }
                style={{
                  color: "blue",
                  cursor: "pointer",
                  textDecoration: "underline"
                }}
              >
                利用規約
              </Text>
              をご確認の上、連携してください。
            </Text>
            <Button
              disabled={isIssuingSmartReceiptToken}
              fullWidth
              fz={16}
              loading={isIssuingSmartReceiptToken}
              onClick={() => void handleSmartReceiptConnection()}
              size="lg"
            >
              スマートレシートと連携する
            </Button>
            <Button
              disabled={isIssuingSmartReceiptToken}
              fullWidth
              fz={16}
              onClick={() => navigate(-1)}
              size="lg"
              variant="outline"
            >
              キャンセル
            </Button>
          </Stack>
        </Box>
      </Stack>
      <Modal
        centered
        onClose={() => setIsModalOpen(false)}
        opened={isModalOpen}
        size="lg"
        styles={(theme) => ({
          body: {
            display: "flex",
            flexDirection: "column",
            height: "100%",
            justifyContent: "space-between",
            padding: 0
          },
          inner: {
            padding: "20px 0"
          }
        })}
      >
        <Box
          style={{
            alignItems: "center",
            display: "flex",
            flex: 1,
            justifyContent: "center",
            padding: "20px"
          }}
        >
          <Text fw={800} size="lg">
            {modalContent.text}
          </Text>
        </Box>
        <Box style={{ padding: "20px" }}>
          <Stack spacing="md">{modalContent.buttons}</Stack>
        </Box>
      </Modal>
      {isLoading && (
        <Box
          style={{
            bottom: 0,
            left: 0,
            position: "fixed",
            right: 0,
            top: 0,
            zIndex: 1000
          }}
        >
          <Overlay color="#000" opacity={0.6} zIndex={1000} />
          <Center
            style={{ height: "100%", position: "relative", zIndex: 1001 }}
          >
            <Loader color="blue" size="xl" />
          </Center>
        </Box>
      )}
    </MainLayout>
  );
};

export default SignupSmartReceiptWithModal;
