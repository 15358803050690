// eslint-disable-next-line import/no-named-as-default
import liff from "@line/liff";
import { Anchor, Flex, Group, Stack, Text } from "@mantine/core";
import { useGetCurrentMember, useGetCurrentPointsBalance } from "api";
import { ReactComponent as ChevronRightIcon } from "assets/images/icons/chevron_right.svg";
import MainLayout from "components/layouts/MainLayout";
import Loading from "components/modules/Loading";
import PaymentToolsCard from "components/modules/PaymentToolsCard";
import GuestToRegularMemberCard from "features/user/components/GuestToRegularMemberCard";
import SmartReceiptCard from "features/user/components/SmartReceiptCard";
import UserBarcodeCard from "features/user/components/UserBarcodeCard";
import UserPointStatsCard from "features/user/components/UserPointStatsCard";
import UserRankCard from "features/user/components/UserRankCard";
import { PAGE_TITLE, ROYAL_HC_MYPAGE_BASE_URL } from "libs/constants";
import type { FC } from "react";

/**
 * ホームページ
 */
const Home: FC = () => {
  const { data: currentMemberData } = useGetCurrentMember();
  const {
    data: currentPointsBalanceData,
    mutate: mutateGetCurrentPointsBalance,
    isValidating: isValidatingGetCurrentPointsBalance
  } = useGetCurrentPointsBalance();

  if (!currentMemberData || !currentPointsBalanceData)
    return <Loading pageTitle={PAGE_TITLE.HOME} />;

  return (
    <MainLayout header footer pageTitle={PAGE_TITLE.HOME} bodyColor="gray">
      <Stack spacing="xl">
        {/* 会員バーコードカード */}
        <UserBarcodeCard
          cardNumber={currentMemberData.card_number}
          currentRankName={currentPointsBalanceData.current_rank_name}
          regularMember={currentPointsBalanceData.regular_member}
        />
        {currentPointsBalanceData.regular_member ? (
          // 現在のランクカード
          <UserRankCard
            currentRankName={currentPointsBalanceData.current_rank_name}
            nextRankName={currentPointsBalanceData.next_rank_name}
            rankUpAmountsRequired={
              currentPointsBalanceData.rank_up_amounts_required
            }
            rankUpJudgementAmount={
              currentPointsBalanceData.rank_up_judgement_amount
            }
          />
        ) : (
          // ゲストから本会員へ昇格する動線カード
          <GuestToRegularMemberCard />
        )}

        {/* スマートレシート表示 */}
        <SmartReceiptCard
          regularMember={currentPointsBalanceData.regular_member}
        />

        {/* 保有ポイント残高 */}
        <Stack spacing={8}>
          {/* ヘッダ */}
          <Flex justify="space-between" align="center">
            {/* 保有ポイント残高 */}
            <Text size="sm" weight="bold">
              保有ポイント残高
            </Text>
            {/* 「履歴」リンク */}
            {currentPointsBalanceData.regular_member && (
              <Anchor
                component="button"
                type="button"
                size="sm"
                weight="bold"
                color="#3078E5"
                onClick={() =>
                  liff.openWindow({
                    external: false,
                    url: `${ROYAL_HC_MYPAGE_BASE_URL}/shop/customer/point.aspx`
                  })
                }
              >
                <Group spacing={4}>
                  履歴
                  <ChevronRightIcon width={14} height={14} />
                </Group>
              </Anchor>
            )}
          </Flex>
          {/* 保有ポイント残高カード */}
          <UserPointStatsCard
            latestExpirationPoint={
              currentPointsBalanceData.latest_expiration_point
            }
            latestExpirationPointDate={
              currentPointsBalanceData.latest_expiration_point_date
            }
            pointBalance={currentPointsBalanceData.point_balance}
            onClickUpdate={() => void mutateGetCurrentPointsBalance()}
            isUpdating={isValidatingGetCurrentPointsBalance}
          />
        </Stack>
        {/* 決済ツールリストカード */}
        <PaymentToolsCard />
      </Stack>
    </MainLayout>
  );
};

export default Home;
