import type {
  ErrorResponseBody,
  ErrorResponseBodyWithHttpStatus,
  GetSmartReceiptMemberPointCardInquiryResponseData,
  GetSmartReceiptTokenIssueUserResponseData,
  PostSmartReceiptTokenIssueResponseData,
  SuccessResponseBody
} from "types/api";
// eslint-disable-next-line import/no-named-as-default
import liff from "@line/liff";
import axios, { isAxiosError } from "axios";
import { BASE_URL } from "libs/constants";
import { handleMoveToErrorPage } from "libs/handleError";
import type { SWRConfiguration } from "swr";
import useSWR from "swr";
import useSWRMutation from "swr/mutation";

// axios instance
const instance = axios.create({ baseURL: BASE_URL });
// interceptors request
instance.interceptors.request.use((config) => {
  config.headers.setContentType("application/json; charset=UTF-8");
  config.headers.set("Access-Token", liff.getAccessToken());
  config.headers.set("Token-Id", liff.getIDToken());
  return config;
});
// interceptors response
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (!isAxiosError<ErrorResponseBody>(error)) return Promise.reject(error);

    switch (error.response?.status) {
      // 401
      case 401:
        // トークン期限切れはエラーページへ
        localStorage.clear();
        handleMoveToErrorPage("session-expired");
        break;
      case 404:
        // 会員未登録 かつ 会員登録済みユーザーのみアクセスできるページ の場合は会員登録トップページへ
        if (
          //
          error.response.data.errors.includes("会員情報が未連携です") &&
          !window.location.pathname.includes("/signup")
        ) {
          window.location.replace(`/signup/top${window.location.search}`);
        }
        break;
    }
    return Promise.reject(error);
  }
);

// Todo: Fetcherは共通化できそう
// ---------- GET ----------
const fetcher = <T = unknown>(path: string) =>
  instance
    .get<SuccessResponseBody<T>>(path)
    .then(
      // レスポンスボディ { "status": "succeeded", "data": データの中身 } の "data" 部を返す
      (res) => res.data.data
    )
    .catch((error) => {
      // レスポンスボディ { "status": "failed", "errors": [エラー内容の配列] } 自体を返す
      // ステータスコードが欲しい場合には追加
      if (
        isAxiosError<ErrorResponseBodyWithHttpStatus>(error) &&
        error.response
      ) {
        return Promise.reject({
          ...error.response.data,
          httpStatus: error.response.status || 500 // ステータスコードを追加
        });
      }
      return Promise.reject(
        isAxiosError<ErrorResponseBody>(error)
          ? error.response?.data
          : { errors: [], status: "failed" }
      );
    });

// ---------- POST ----------
const fetcherForPOST = <T = unknown>(path: string, { arg }: { arg: object }) =>
  instance
    .post<SuccessResponseBody<T>>(path, arg)
    .then(
      // レスポンスボディ { "status": "succeeded", "data": データの中身 } の "data" 部を返す
      (res) => res.data.data
    )
    .catch((error) =>
      // レスポンスボディ { "status": "failed", "errors": [エラー内容の配列] } 自体を返す
      Promise.reject(
        isAxiosError<ErrorResponseBody>(error)
          ? error.response?.data
          : { errors: [], status: "failed" }
      )
    );

/** API スマートレシート会員情報取得API */
export const useGetSmartReceiptMemberPointCardInquiry = (
  options: SWRConfiguration = {}
) =>
  useSWR<
    GetSmartReceiptMemberPointCardInquiryResponseData,
    ErrorResponseBodyWithHttpStatus
  >(
    "/users/current/smart_receipt/member_point_card_inquiries",
    fetcher<GetSmartReceiptMemberPointCardInquiryResponseData>,
    options
  );

/** API スマートレシートトークン発行API */
export const usePostSmartReceiptTokenIssue = () =>
  useSWRMutation<
    PostSmartReceiptTokenIssueResponseData, // 成功時のレスポンス型
    ErrorResponseBody, // エラー時のレスポンス型
    string // キー（APIのエンドポイント）
  >("/users/current/smart_receipt/token_issues", fetcherForPOST);

/** API スマートレシートトークン発行会員情報取得API */
export const useGetSmartReceiptTokenIssueUser = (
  options: SWRConfiguration = {}
) =>
  useSWR<
    GetSmartReceiptTokenIssueUserResponseData,
    ErrorResponseBodyWithHttpStatus
  >(
    "/users/current/smart_receipt/token_issue_users",
    fetcher<GetSmartReceiptTokenIssueUserResponseData>,
    options
  );
