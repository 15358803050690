// eslint-disable-next-line import/no-named-as-default
import liff from "@line/liff";
import {
  Button,
  NavLink,
  Stack,
  Text,
  Title,
  type NavLinkProps
} from "@mantine/core";
import { useGetCurrentPointsBalance, useGetUsersUrls } from "api";
import { ReactComponent as ChevronRightIcon } from "assets/images/icons/chevron_right.svg";
import MainLayout from "components/layouts/MainLayout";
import Loading from "components/modules/Loading";
import {
  HELP_MENU_LINKS,
  MEMBER_MENU_LINKS,
  OTHER_MENU_LINKS,
  PAGE_TITLE
} from "libs/constants";
import type { FC } from "react";

// NavLink で使いまわすデザイン関連の props を抜き出したもの
const commonNavLinkProps: NavLinkProps = {
  fw: "bold",
  px: 8,
  py: "md",
  rightSection: <ChevronRightIcon width={20} height={20} />,
  styles: (theme) => ({
    label: { color: theme.black },
    rightSection: { color: "rgba(197, 197, 197, 1)" },
    root: { borderBottom: "1px solid #C5C5C5" }
  })
};

/**
 * メニューページ
 */
const Menu: FC = () => {
  const {
    data: currentPointsBalanceData,
    isValidating: isValidatingGetCurrentPointsBalance
  } = useGetCurrentPointsBalance();
  const { data: usersUrlsData, isValidating: isValidatingGetUsersUrls } =
    useGetUsersUrls(
      !!currentPointsBalanceData && !currentPointsBalanceData.regular_member // ゲスト会員のみフェッチを実行
    );

  // 最新データ取得完了まではロード中を表示
  if (isValidatingGetCurrentPointsBalance || !currentPointsBalanceData)
    return <Loading pageTitle={PAGE_TITLE.MENU} />;

  return (
    <MainLayout header={true} footer={true} pageTitle={PAGE_TITLE.MENU}>
      <Stack spacing="md">
        {/* タイトル */}
        <Title order={2} size="h4" align="center">
          メニュー
        </Title>
        {/* コンテンツ */}
        <Stack spacing="md">
          {/* マイページ */}
          <Stack spacing={0}>
            <Title order={3} size="h6" px={8} py="md" bg="#C4C4C4">
              マイページ
            </Title>
            {currentPointsBalanceData.regular_member ? (
              // 本会員の場合
              <>
                {/* 購入履歴 */}
                <NavLink
                  {...commonNavLinkProps}
                  label="購入履歴"
                  onClick={() =>
                    liff.openWindow({
                      external: false,
                      url: MEMBER_MENU_LINKS.PURCHASE_HISTORY
                    })
                  }
                />
                {/* ポイント履歴 */}
                <NavLink
                  {...commonNavLinkProps}
                  label="ポイント履歴"
                  onClick={() =>
                    liff.openWindow({
                      external: false,
                      url: MEMBER_MENU_LINKS.POINT_HISTORY
                    })
                  }
                />
                {/* 招待コード */}
                <NavLink
                  {...commonNavLinkProps}
                  label="招待コード"
                  onClick={() =>
                    liff.openWindow({
                      external: false,
                      url: MEMBER_MENU_LINKS.INVITATION_CODE
                    })
                  }
                />
                {/* 会員情報変更 */}
                <NavLink
                  {...commonNavLinkProps}
                  label="会員情報変更"
                  onClick={() =>
                    liff.openWindow({
                      external: false,
                      url: MEMBER_MENU_LINKS.PROFILE_UPDATE
                    })
                  }
                />
              </>
            ) : (
              // ゲスト会員の場合
              <Stack spacing="xs">
                <Text>マイページのご利用には会員登録が必要です。</Text>
                <Button
                  size="lg"
                  fullWidth
                  fz={16}
                  loading={!usersUrlsData || isValidatingGetUsersUrls} // データ未取得 or 最新データ取得中はクリックさせない
                  onClick={() =>
                    usersUrlsData &&
                    liff.openWindow({ external: false, url: usersUrlsData.url })
                  }
                >
                  会員情報を登録する
                </Button>
              </Stack>
            )}
          </Stack>
          {/* その他 */}
          <Stack spacing={0}>
            <Title order={3} size="h6" px={8} py="md" bg="#C4C4C4">
              その他
            </Title>
            {/* アプリダウンロード */}
            <NavLink
              {...commonNavLinkProps}
              component="a"
              label="アプリダウンロード"
              href={
                liff.getOS() === "android"
                  ? OTHER_MENU_LINKS.APP_DOWNLOAD_ANDROID
                  : OTHER_MENU_LINKS.APP_DOWNLOAD_IOS
              }
            />
            {/* お知らせ */}
            <NavLink
              {...commonNavLinkProps}
              label="お知らせ"
              onClick={() =>
                liff.openWindow({
                  external: false,
                  url: OTHER_MENU_LINKS.NOTIFICATION
                })
              }
            />
            {/* チラシ */}
            <NavLink
              {...commonNavLinkProps}
              label="チラシ"
              onClick={() =>
                liff.openWindow({
                  external: false,
                  url: OTHER_MENU_LINKS.FLYER
                })
              }
            />
            {/* お役立ち情報 */}
            <NavLink
              {...commonNavLinkProps}
              label="お役立ち情報"
              onClick={() =>
                liff.openWindow({
                  external: false,
                  url: OTHER_MENU_LINKS.USEFUL_INFORMATION
                })
              }
            />
            {/* ロイヤルホームセンターLINE公式アカウント */}
            <NavLink
              {...commonNavLinkProps}
              component="a"
              label="ロイヤルホームセンターLINE公式アカウント"
              href={OTHER_MENU_LINKS.ROYAL_HC_LINE_ACCOUNT}
            />
            {/* ロイヤルホームセンター公式HP */}
            <NavLink
              {...commonNavLinkProps}
              label="ロイヤルホームセンター公式HP"
              onClick={() =>
                liff.openWindow({
                  external: false,
                  url: OTHER_MENU_LINKS.ROYAL_HC_HP
                })
              }
            />
            {/* ロイサポート */}
            <NavLink
              {...commonNavLinkProps}
              label="ロイサポート"
              onClick={() =>
                liff.openWindow({
                  external: false,
                  url: OTHER_MENU_LINKS.ROY_SUPPORT
                })
              }
            />
            {/* リフォーム */}
            <NavLink
              {...commonNavLinkProps}
              label="リフォーム"
              onClick={() =>
                liff.openWindow({
                  external: false,
                  url: OTHER_MENU_LINKS.REFORM
                })
              }
            />
          </Stack>
          {/* ヘルプ */}
          <Stack spacing={0}>
            <Title order={3} size="h6" px={8} py="md" bg="#C4C4C4">
              ヘルプ
            </Title>
            {/* プライバシーポリシー */}
            <NavLink
              {...commonNavLinkProps}
              label="プライバシーポリシー"
              onClick={() =>
                liff.openWindow({
                  external: false,
                  url: HELP_MENU_LINKS.PRIVACY_POLICY
                })
              }
            />
            {/* お問い合わせ */}
            <NavLink
              {...commonNavLinkProps}
              label="お問い合わせ"
              onClick={() =>
                liff.openWindow({
                  external: false,
                  url: HELP_MENU_LINKS.CONTACT
                })
              }
            />
            {/* よくある質問 */}
            <NavLink
              {...commonNavLinkProps}
              label="よくある質問"
              onClick={() =>
                liff.openWindow({
                  external: false,
                  url: HELP_MENU_LINKS.QA
                })
              }
            />
          </Stack>
        </Stack>
      </Stack>
    </MainLayout>
  );
};

export default Menu;
