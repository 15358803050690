import { PAGE_TITLE } from "libs/constants";
import Home from "pages/Home";
import RootBoundary from "pages/RootBoundary";
import GuestCouponTop from "pages/coupon/GuestCouponTop";
import Menu from "pages/menu/Menu";
import SignupTop from "pages/signup/SignupTop";
import SignupMemberNew from "pages/signup/member/SignupMemberNew";
import SignupMemberSent from "pages/signup/member/SignupMemberSent";
import SignupMemberVerify from "pages/signup/member/SignupMemberVerify";
import GuestSmartReceipt from "pages/smart_receipt/GuestSmartReceipt";
import SignupSmartReceiptWithModal from "pages/smart_receipt/SignupSmartReceiptWithModal";
import type { FC } from "react";
import { Outlet, RouterProvider, createBrowserRouter } from "react-router-dom";
import RequireMember from "routes/RequireMember";

// eslint-disable-next-line import/no-named-export
export const routes = [
  {
    children: [
      // ホーム
      {
        element: (
          <RequireMember isRequire pageTitle={PAGE_TITLE.HOME}>
            <Home />
          </RequireMember>
        ),
        index: true
      },
      // 会員登録トップ
      {
        element: (
          <RequireMember isRequire={false} pageTitle={PAGE_TITLE.SIGNUP}>
            <SignupTop />
          </RequireMember>
        ),
        path: "/signup/top"
      },
      // 会員登録 登録済み会員による作成ページ
      {
        element: (
          <RequireMember isRequire={false} pageTitle={PAGE_TITLE.SIGNIN}>
            <SignupMemberNew />
          </RequireMember>
        ),
        path: "/signup/member/new"
      },
      // 会員登録 登録済み会員メール送信完了ページ
      {
        element: (
          <RequireMember isRequire={false} pageTitle={PAGE_TITLE.SIGNIN}>
            <SignupMemberSent />
          </RequireMember>
        ),
        path: "/signup/member/sent"
      },
      // 会員登録 登録済み会員認証ページ
      {
        element: (
          <RequireMember isRequire={false} pageTitle={PAGE_TITLE.SIGNIN}>
            <SignupMemberVerify />
          </RequireMember>
        ),
        path: "/signup/member/verify"
      },
      // クーポン
      {
        element: (
          <RequireMember isRequire pageTitle={PAGE_TITLE.COUPON}>
            <GuestCouponTop />
          </RequireMember>
        ),
        path: "/coupon"
      },
      // メニュー
      {
        element: (
          <RequireMember isRequire pageTitle={PAGE_TITLE.MENU}>
            <Menu />
          </RequireMember>
        ),
        path: "/menu"
      },
      // GuestSmartReceipt
      {
        element: (
          <RequireMember isRequire pageTitle={PAGE_TITLE.GUEST_SMART_RECEIPT}>
            <GuestSmartReceipt />
          </RequireMember>
        ),
        path: "/smart-receipt/guest"
      },
      // SignupSmartReceipt
      {
        element: (
          <RequireMember isRequire pageTitle={PAGE_TITLE.SIGNUP_SMART_RECEIPT}>
            <SignupSmartReceiptWithModal />
          </RequireMember>
        ),
        path: "/smart-receipt/signup"
      }
    ],
    element: <Outlet />,
    errorElement: <RootBoundary />,
    path: "/"
  }
];

const AppRoutes: FC = () => {
  return <RouterProvider router={createBrowserRouter(routes)} />;
};

export default AppRoutes;
