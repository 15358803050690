import { Card, Center, Image, Space, Stack, Text, rem } from "@mantine/core";
import rank_card_bronze from "assets/images/rank_card_bronze.png";
import rank_card_gold from "assets/images/rank_card_gold.png";
import rank_card_guest from "assets/images/rank_card_guest.png";
import rank_card_regular from "assets/images/rank_card_regular.png";
import rank_card_silver from "assets/images/rank_card_silver.png";
import type { FC } from "react";
import Barcode from "react-barcode";
import Clock from "react-live-clock";
import type {
  GetCurrentMemberResponseData,
  GetCurrentPointsBalanceResponseData
} from "types/api";
import type { UserRankName } from "types/user";

/** 会員ランク名で表示する画像(src属性)対応表 */
const RANK_IMAGE_MAP: { [key in UserRankName]: string } = {
  ゴールド: rank_card_gold,
  シルバー: rank_card_silver,
  ブロンズ: rank_card_bronze,
  レギュラー: rank_card_regular
};

type Props = {
  cardNumber: GetCurrentMemberResponseData["card_number"];
  currentRankName: UserRankName;
  regularMember: GetCurrentPointsBalanceResponseData["regular_member"];
};

/**
 * 会員バーコードカード
 */
const UserBarcodeCard: FC<Props> = ({
  cardNumber,
  currentRankName,
  regularMember
}) => {
  return (
    <Card shadow="md" padding="md" radius={10}>
      {/* ランク画像 */}
      <Card.Section>
        <Image
          src={
            regularMember ? RANK_IMAGE_MAP[currentRankName] : rank_card_guest
          }
          fit="contain"
          alt={`${regularMember ? currentRankName : "ゲスト"}ランクカード`}
          caption={cardNumber.replace(/\d{4}(?=.)/g, "$& ")}
          styles={(theme) => ({
            caption: {
              bottom: rem(10),
              color: theme.black,
              fontSize: theme.fontSizes.md,
              left: rem(10),
              position: "absolute"
            },
            figure: { position: "relative" }
          })}
        />
      </Card.Section>
      <Space h="lg" />
      <Stack spacing="lg">
        {/* バーコード */}
        <Center>
          <Barcode
            value={cardNumber}
            format="EAN13"
            displayValue={false}
            margin={0}
            height={36}
          />
        </Center>
        {/* 時計 */}
        <Text
          size="sm"
          align="right"
          component={Clock}
          format={"YYYY/MM/DD HH:mm:ss"}
          timezone={"Asia/Tokyo"}
          ticking
        />
      </Stack>
    </Card>
  );
};

export default UserBarcodeCard;
