// eslint-disable-next-line import/no-named-as-default
import liff from "@line/liff";
import { Button, Stack, Text } from "@mantine/core";
import { useGetUsersUrls } from "api";
import MainLayout from "components/layouts/MainLayout";
import Loading from "components/modules/Loading";
import { PAGE_TITLE } from "libs/constants";
import type { FC } from "react";
import { useNavigate } from "react-router-dom";

const GuestSmartReceipt: FC = () => {
  const navigate = useNavigate();
  const { data: usersUrlsData, isValidating: isValidatingGetUsersUrls } =
    useGetUsersUrls();

  const handleGoBack = () => {
    navigate(-1);
  };

  if (isValidatingGetUsersUrls) {
    return <Loading pageTitle={PAGE_TITLE.GUEST_SMART_RECEIPT} />;
  }

  return (
    <MainLayout
      header
      footer={false}
      pageTitle={PAGE_TITLE.GUEST_SMART_RECEIPT}
    >
      <Stack mih={`calc(100dvh - ${16 * 2}px)`} spacing="md">
        {!usersUrlsData?.url ? (
          <Stack spacing="md">
            <Text size="lg" fw={800}>
              必要な情報が見つかりませんでした。前のページから再度アクセスしてください。
            </Text>
            <Button onClick={handleGoBack} size="lg" fz={16} variant="outline">
              戻る
            </Button>
          </Stack>
        ) : (
          <>
            <Stack spacing="md">
              <Text size="lg" fw={800}>
                スマートレシートのご利用にはロイヤルホームセンター会員登録が必要です。
              </Text>
            </Stack>
            <Button
              size="lg"
              fullWidth
              fz={16}
              mt="auto"
              pos="sticky"
              bottom={16}
              onClick={() =>
                liff.openWindow({ external: false, url: usersUrlsData.url })
              }
            >
              会員情報を登録する
            </Button>
          </>
        )}
      </Stack>
    </MainLayout>
  );
};

export default GuestSmartReceipt;
