import { AppShell, Container, Global } from "@mantine/core";
import Footer from "components/modules/Footer";
import Header from "components/modules/Header";
import { PAGE_TITLE } from "libs/constants";
import type { FC, ReactNode } from "react";
import { Helmet } from "react-helmet-async";

type MainLayoutProps = {
  bodyColor?: "white" | "gray"; // 全体の背景色を指定
  children: ReactNode;
  footer?: boolean; // フッタ 表示・非表示管理
  header?: boolean; // ヘッダ 表示・非表示管理
  pageTitle?: string;
};

const MainLayout: FC<MainLayoutProps> = ({
  header = true,
  footer = true,
  pageTitle,
  bodyColor = "white",
  children
}) => {
  return (
    <>
      {bodyColor === "gray" && (
        // body の背景色を #F2F2F2 で上書き
        <Global
          styles={(theme) => ({
            body: { ...theme.globalStyles, backgroundColor: "#F2F2F2" }
          })}
        />
      )}
      <Helmet>
        <title>{pageTitle ?? PAGE_TITLE.DEFAULT}</title>
      </Helmet>
      <AppShell
        padding={0}
        header={header ? <Header /> : undefined}
        footer={footer ? <Footer /> : undefined}
        sx={{
          main: {
            height: "100dvh", // こちらのdvhの設定が優先ですが、dvhの設定が効かない場合、vhのheightが効きます。dvhのがさらに汎用的になったらこちらを削除しstylesのheightをdvhに変更します。
            minHeight: "100dvh"
          }
        }}
        h="100vh"
        styles={{
          main: {
            height: "100vh",
            overflowY: "scroll"
          }
        }}
      >
        <Container
          size="sm" // 横幅に上限を設ける
          p="md"
        >
          {children}
        </Container>
      </AppShell>
    </>
  );
};
export default MainLayout;
