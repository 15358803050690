import type {
  DefaultMantineColor,
  MantineThemeOverride,
  Tuple
} from "@mantine/core";

/**
 * テーマ
 *
 * https://mantine.dev/theming/theme-object/#store-theme-override-object-in-a-variable
 */
export const theme: MantineThemeOverride = {
  black: "#1D1D1B",
  colorScheme: "light",
  colors: {
    /**
     * ブランド(brand)カラー
     *
     * メインカラー(#005E3C)をもとに↓で10色作成
     * https://omatsuri.app/color-shades-generator
     *
     * TODO: デザイン fix 時に見直す必要あり
     */
    brand: [
      "#3D6959",
      "#336453",
      "#29614D",
      "#1F5F47",
      "#145D43",
      "#0A5D3F",
      "#005E3C", // こいつがメインカラー index: 6
      "#084C34",
      "#0E3E2D",
      "#113427"
    ]
  },
  components: {
    Button: {
      styles: {
        label: {
          whiteSpace: "normal" // 表示幅が狭いときに文字列を折り返す
        }
      }
    }
  },
  fontFamily: "'Noto Sans JP', sans-serif",
  globalStyles: (_) => ({
    "html, body": { height: "100%", overflow: "hidden" }
  }),
  primaryColor: "brand",
  primaryShade: 6,
  white: "#FFFFFF"
};

/**
 * カラー型の拡張
 *
 * https://mantine.dev/theming/colors/#add-custom-colors-types
 */
declare module "@mantine/core" {
  export interface MantineThemeColorsOverride {
    colors: Record<"brand" | DefaultMantineColor, Tuple<string, 10>>;
  }
}
