import { useGetCurrentMember } from "api";
import type MainLayout from "components/layouts/MainLayout";
import Loading from "components/modules/Loading";
import { handleMoveToErrorPage } from "libs/handleError";
import type { ComponentProps, FC, ReactNode } from "react";
import { Navigate } from "react-router-dom";

type Props = Pick<ComponentProps<typeof MainLayout>, "pageTitle"> & {
  children: ReactNode;
  /**
   * 会員登録の必要・不要
   *
   * 会員登録済みユーザーのみアクセスできるページの場合: true
   * 会員未登録ユーザーのみアクセスできるページの場合: false
   */
  isRequire?: boolean;
};

/**
 * 会員登録状況をチェックするコンポーネント
 *
 * 参考: https://github.com/remix-run/react-router/blob/dev/examples/auth/src/App.tsx
 */
const RequireMember: FC<Props> = ({
  isRequire = true,
  pageTitle,
  children
}) => {
  const { data, error, isLoading } = useGetCurrentMember({
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    shouldRetryOnError: false
  });

  // ロード中
  if (isLoading) return <Loading header={false} pageTitle={pageTitle} />;

  // API 接続自体に失敗した場合はエラーページへ
  if (!data && !error) {
    handleMoveToErrorPage("server-error");
    return null;
  }

  // 会員登録済みかどうか
  const isMember = !error?.errors.includes("会員情報が未連携です");

  // 会員登録済みユーザーのみアクセスできるページの場合: 会員未登録ユーザーは会員登録トップページへ
  if (isRequire && !isMember) return <Navigate to="/signup/top" />;

  // 会員未登録ユーザーのみアクセスできるページの場合: 会員登録済みユーザーはホームページへ
  if (!isRequire && isMember) return <Navigate to="/" />;

  return children;
};

export default RequireMember;
