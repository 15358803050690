import type {
  ErrorResponseBody,
  ErrorResponseBodyWithHttpStatus,
  GetCurrentMemberResponseData,
  GetCurrentPointsBalanceResponseData,
  GetUsersUrlsResponseData,
  PostUsersConfirmAuthRequestData,
  PostUsersConfirmAuthResponseData,
  PostUsersConfirmSendRequestData,
  PostUsersConfirmSendResponseData,
  PostUsersGuestsRequestData,
  PostUsersGuestsResponseData,
  SuccessResponseBody
} from "types/api";
// eslint-disable-next-line import/no-named-as-default
import liff from "@line/liff";
import axios, { isAxiosError } from "axios";
import { BASE_URL } from "libs/constants";
import { handleMoveToErrorPage } from "libs/handleError";
import type { SWRConfiguration } from "swr";
import useSWR from "swr";
import useSWRMutation from "swr/mutation";

// axios instance
const instance = axios.create({ baseURL: BASE_URL });
// interceptors request
instance.interceptors.request.use((config) => {
  config.headers.setContentType("application/json; charset=UTF-8");
  config.headers.set("Access-Token", liff.getAccessToken());
  config.headers.set("Token-Id", liff.getIDToken());
  return config;
});
// interceptors response
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (!isAxiosError<ErrorResponseBody>(error)) return Promise.reject(error);

    switch (error.response?.status) {
      // 401
      case 401:
        // トークン期限切れはエラーページへ
        localStorage.clear();
        handleMoveToErrorPage("session-expired");
        break;
      case 404:
        // 会員未登録 かつ 会員登録済みユーザーのみアクセスできるページ の場合は会員登録トップページへ
        if (
          error.response.data.errors.includes("会員情報が未連携です") &&
          !window.location.pathname.includes("/signup")
        ) {
          window.location.replace(`/signup/top${window.location.search}`);
        }
        break;
      // 500
      case 500: // Internal Server Error
      case 501: // Not Implemented
      case 502: // Bad Gateway
      case 503: // Service Unavailable
      case 504: // Gateway Timeout
      case 505: // HTTP Version Not Supported
      case 506: // Variant Also Negotiates
      case 507: // Insufficient Storage
      case 508: // Loop Detected
      case 510: // Not Extended
      case 511: // Network Authentication Required
        // エラーページへ
        handleMoveToErrorPage("server-error", error.response.status);
        break;
    }
    return Promise.reject(error);
  }
);

// ---------- GET ----------
const fetcher = <T = unknown>(path: string) =>
  instance
    .get<SuccessResponseBody<T>>(path)
    .then(
      // レスポンスボディ { "status": "succeeded", "data": データの中身 } の "data" 部を返す
      (res) => res.data.data
    )
    .catch((error) => {
      // レスポンスボディ { "status": "failed", "errors": [エラー内容の配列] } 自体を返す
      // ステータスコードが欲しい場合には追加
      if (
        isAxiosError<ErrorResponseBodyWithHttpStatus>(error) &&
        error.response
      ) {
        return Promise.reject({
          ...error.response.data,
          httpStatus: error.response.status || 500 // ステータスコードを追加
        });
      }
      return Promise.reject(
        isAxiosError<ErrorResponseBody>(error)
          ? error.response?.data
          : { errors: [], status: "failed" }
      );
    });

/** API001 会員照会API */
export const useGetCurrentMember = (options: SWRConfiguration = {}) =>
  useSWR<GetCurrentMemberResponseData, ErrorResponseBody>(
    "/package/current/member",
    fetcher<GetCurrentMemberResponseData>,
    options
  );

/** API005 新規会員登録ページURL取得API */
export const useGetUsersUrls = (
  shouldFetch = true, // フェッチするかどうか(デフォルト true) 参考: https://swr.vercel.app/ja/docs/conditional-fetching#conditional
  options: SWRConfiguration = {}
) =>
  useSWR<GetUsersUrlsResponseData, ErrorResponseBody>(
    () => (shouldFetch ? "/users/urls" : null),
    fetcher<GetUsersUrlsResponseData>,
    options
  );

/** API006 ポイント残高取得API */
export const useGetCurrentPointsBalance = (options: SWRConfiguration = {}) =>
  useSWR<GetCurrentPointsBalanceResponseData, ErrorResponseBody>(
    "/users/current/points/balance",
    fetcher<GetCurrentPointsBalanceResponseData>,
    options
  );

// ---------- POST ----------
const fetcherForPOST = <T = unknown>(path: string, { arg }: { arg: object }) =>
  instance
    .post<SuccessResponseBody<T>>(path, arg)
    .then(
      // レスポンスボディ { "status": "succeeded", "data": データの中身 } の "data" 部を返す
      (res) => res.data.data
    )
    .catch((error) =>
      // レスポンスボディ { "status": "failed", "errors": [エラー内容の配列] } 自体を返す
      Promise.reject(
        isAxiosError<ErrorResponseBody>(error)
          ? error.response?.data
          : { errors: [], status: "failed" }
      )
    );

/** API002 ゲスト会員発行API */
export const usePostUsersGuests = () =>
  useSWRMutation<
    PostUsersGuestsResponseData, // data
    ErrorResponseBody, // error
    string, // key
    PostUsersGuestsRequestData // リクエストボディ
  >("/users/guests", fetcherForPOST);

/** API003 会員情報照会&メール送信 */
export const usePostUsersConfirmSend = () =>
  useSWRMutation<
    PostUsersConfirmSendResponseData, // data
    ErrorResponseBody, // error
    string, // key
    PostUsersConfirmSendRequestData // リクエストボディ
  >("/users/confirm/send", fetcherForPOST);

/** API004 トークン認証 */
export const usePostUsersConfirmAuth = () =>
  useSWRMutation<
    PostUsersConfirmAuthResponseData, // data
    ErrorResponseBody, // error
    string, // key
    PostUsersConfirmAuthRequestData // リクエストボディ
  >("/users/confirm/auth", fetcherForPOST);
